<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="제안 의견" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="false"
                  label="제목"
                  name="boardTitle"
                  v-model="data.boardTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="false"
                  label="의견게시일"
                  name="boardDate"
                  v-model="data.boardDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="false"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field 
                  :editable="false"
                  type="dept_user" 
                  label="작성자" 
                  name="regUserId" 
                  v-model="data.regUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  required
                  :editable="false"
                  :rows="8"
                  label="의견"
                  name="boardContents"
                  v-model="data.boardContents">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-upload 
                  :attachInfo="attachInfoRequest"
                  :editable="false"
                  label="제안의견 첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="의견 결과" class="cardClassDetailForm" bgClass="orange-2"> 
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave2"
                  :param="data"
                  :mappingType="mappingType"
                  label="처리결과 저장"
                  icon="save"
                  @beforeAction="saveData2"
                  @btnCallback="saveCallback2" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="SOP_BOARD_RESULT_CD"
                  itemText="codeName"
                  itemValue="code"
                  type="edit"
                  name="resultCd"
                  label="처리결과"
                  v-model="data.resultCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :rows="8"
                  label="의견 처리 결과"
                  name="boardResultContent"
                  v-model="data.boardResultContent">
                </c-textarea>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="의견결과 첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="gridImpr"
            title="제안의견 개선 요청"
            :columns="gridImpr.columns"
            :data="gridImpr.data"
            :gridHeight="gridheight2"
            :usePaging="false"
            :filtering="false"
            :columnSetting="false"
            @linkClick="linkClick"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="개선요청" :showLoading="false"  icon="add" @btnClicked="addImpr" />
                <c-btn v-if="editable" label="즉시조치" :showLoading="false"  icon="add" @btnClicked="addiimImpr" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'saf-opinion-board-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        boardId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD',
        taskKey: '',
      },
      attachInfoRequest: {
        isSubmit: '',
        taskClassCd: 'SOB_BOARD_REQUEST',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'policyComments',
            field: 'policyComments',
            label: '내용',
            align: 'left',
            style: 'width:70%',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'regName',
            field: 'regName',
            label: '작성자/작성일',
            align: 'center',
            style: 'width:20%',
            sortable: false,
          },
          {
            name: 'complete',
            field: 'complete',
            label: '저장/삭제',
            align: 'center',
            style: 'width:10%',
            sortable: false,
            type: "custom",
          },
        ],
        height: "450px",
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      gridheight2: '200px',
      saveUrl: transactionConfig.sai.sob.board.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      deletable: false,
      isSave: false,
      isSave2: false,
      isdelete: false,
      impListUrl: '',
      data: {
        boardId: '',
        plantCd: '',
        boardDate: '',
        boardContents: '',
        boardTitle: '',
        boardTypeCd: null,
        boardProblem: '',
        boardResultContent: '',
        boardImprove: '',
        boardBenefit: '',
        boardLocation: '',
        policyCommentModelList: [],
        regUserId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      isOwner: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.data.boardDate = this.$comm.getToday();
      this.data.regUserId = this.$store.getters.user.userId;
      // url setting
      this.detailUrl = selectConfig.sai.sob.board.get.url;
      this.insertUrl = transactionConfig.sai.sob.board.insert.url;
      this.updateUrl = transactionConfig.sai.sob.board.update.url;
      this.deleteUrl = transactionConfig.sai.sob.board.delete.url;
      this.impListUrl = selectConfig.sop.ibm.improve.others.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.boardId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.boardId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.regUserId == this.$store.getters.user.userId) {
            this.isOwner = true;
            this.deletable = true;
          } else {
            this.isOwner = false;
            this.deletable = false;
          }
          this.getImpList();
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.boardId)
          this.$set(this.attachInfoRequest, 'taskKey', this.popupParam.boardId)
        },);
      } else {
        this.data.regUserId = this.$store.getters.user.userId
      }
    },
    saveData() {
      if (this.popupParam.boardId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?', 
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              // this.data.regUserId = this.$store.getters.user.userId;
              // this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveData2() {
      if (this.popupParam.boardId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?', 
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              // this.data.regUserId = this.$store.getters.user.userId;
              // this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave2 = !this.isSave2;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.boardId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$set(this.popupParam, 'boardId', result.data)
        this.$set(this.attachInfo, 'taskKey', this.popupParam.boardId)
        this.$set(this.attachInfo, 'isSubmit', uid())
        this.$set(this.attachInfoRequest, 'taskKey', this.popupParam.boardId)
        this.$set(this.attachInfoRequest, 'isSubmit', uid())
      }
      this.getDetail();
    },
    saveCallback2(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$set(this.popupParam, 'boardId', result.data)
        this.$set(this.attachInfo, 'taskKey', this.popupParam.boardId)
        this.$set(this.attachInfo, 'isSubmit', uid())
        this.$set(this.attachInfoRequest, 'taskKey', this.popupParam.boardId)
        this.$set(this.attachInfoRequest, 'isSubmit', uid())
      }
      this.getDetail();
    },
    saveComment(data) {
      if (!data.policyComments) {
          window.getApp.$emit('ALERT', {
          title: '안내',
          message: '필수 입력값을 입력해 주세요. [내용]',  
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '댓글저장하시겠습니까?', 
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (data.editFlag && data.editFlag === 'C') {
            this.$http.url = transactionConfig.sai.shm.policy.insert.comment.url;
            this.$http.type = 'POST';
          } else {
            this.$http.url = transactionConfig.sai.shm.policy.update.comment.url;
            this.$http.type = 'PUT';
          }
          this.$http.param = data;
          this.$http.request((_result) => {
            data.policyCommentId = _result.data
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeComment(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(transactionConfig.sai.shm.policy.delete.comment.url,
            this.popupParam.boardId, data.policyCommentId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.$refs['table'].selected = [];
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.data.policyCommentModelList = this.$_.reject(this.data.policyCommentModelList, {
              policyCommentId: data.policyCommentId,
            });
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    add() {
      this.data.policyCommentModelList.splice(0, 0, {
        boardId: this.popupParam.boardId,
        policyCommentId: uid(),
        policyComments: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        regName: this.$store.getters.user.userName + ' / ' + this.$comm.getTodayDateTime(),
        editFlag: "C",
      });
    },
    getImpList() {
      if (this.popupParam.boardId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000220', 
          ibmTaskUnderTypeCd: 'ITTU000250', 
          relationTableKey: this.popupParam.boardId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.data.boardTitle,
        relationTableKey: this.popupParam.boardId,
        ibmTaskTypeCd: 'ITT0000220',
        ibmTaskUnderTypeCd: 'ITTU000250', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.data.boardTitle,
        relationTableKey: this.popupParam.boardId,
        ibmTaskTypeCd: 'ITT0000220',
        ibmTaskUnderTypeCd: 'ITTU000250', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    linkClick(row) {
      this.requestImprRow = row
      this.imprData = row;
      if (row.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: row.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
  }
};
</script>
